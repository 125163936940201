import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Automatically detect user language
  .use(initReactI18next) // Bind to React
  .init({
    resources: {
      en: {
        translation: require('./locales/en/translation.json')
      },
      lv: {
        translation: require('./locales/lv/translation.json')
      },
      ru: {
        translation: require('./locales/ru/translation.json')
      }
    },
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false // React already escapes by default
    }
  });

export default i18n;
