import React from "react";
import feature1 from "../assets/images/feature1.jpg";
import feature2 from "../assets/images/feature2.jpg";
import feature3 from "../assets/images/feature3.jpg";
import { useTranslation } from "react-i18next";
import "../i18n";

const Features = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <section className="py-5" id="features" data-aos="fade-up">
        <div className="text-center mb-5">
          <p className="subtitle">{t("Features")}</p>
          <h2>{t("FeaturesTitle")}</h2>
        </div>
        <div className="container">
          <div className="row d-flex justify-content-center g-3 mt-5">
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex flex-column flex-md-row">
                    <div className="col d-flex align-items-center">
                      <img
                        src={feature3}
                        className="rounded-5"
                        width="100%"
                        alt="..."
                      />
                    </div>
                    <div className="col">
                      <h4 className="mt-3 fw-bold">{t("Feature1")}</h4>
                      <p className="mt-1 text-break">
                        {t("Feature1Description")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex flex-column flex-md-row">
                    <div className="col d-flex align-items-center">
                      <img
                        src={feature2}
                        className="rounded-5"
                        width="100%"
                        alt="..."
                      />
                    </div>
                    <div className="col">
                      <h4 className="mt-3 fw-bold">
                       {t("Feature2")}
                      </h4>
                      <p className="mt-1 text-break">
                        {t("Feature2Description")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex flex-column flex-md-row">
                    <div className="col d-flex align-items-center">
                      <img
                        src={feature1}
                        className="rounded-5"
                        width="100%"
                        alt="..."
                      />
                    </div>
                    <div className="col">
                      <h4 className="mt-3 fw-bold">
                        {t("Feature3")}
                      </h4>
                      <p className="mt-1 text-break">
                        {t("Feature3Description")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <iframe
                style={{ width: "100%", height: "500px" }}
                src="https://www.youtube.com/embed/mLqOOqETnus?si=sIfmEUuKYPmz5QCj?&mute=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
