import React from "react";
import project1 from "../assets/images/project1.jpg";
import project2 from "../assets/images/project2.jpg";
import project3 from "../assets/images/project3.jpg";
import project4 from "../assets/images/project4.jpg";
import project5 from "../assets/images/project5.jpg";
import { useTranslation } from "react-i18next";
import "../i18n";

const Projects = () => {
  const { t, } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <section className="py-5" id="projects" data-aos="fade-up">
        <div className="text-center mb-5">
          <p className="subtitle">{t("Project")}</p>
          <h2 className="mb-4">{t("ProjectTitle")}</h2>
          <p>{t("ProjectDescription")}</p>
          <a href="mailto:pavels.polezajevs@gmail.com">
            <button className="btn btn-lg">{t("ContactUs")}</button>
          </a>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="owl-carousel owl-theme app-carousel">
                <div className="item">
                  <img className="img-fluid" src={project1} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={project2} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={project3} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={project4} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={project5} alt="#" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
