import "../src/assets/scss/css/styles.min.css";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router";
import AOS from "aos";
import "aos/dist/aos.css";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import Home from "./Home";
import DronePrivateLessons from "./DronePrivateLessons";
import MappingSurvey from "./MappingSurvey";
import DroneInspection from "./DroneInspection";
import DroneRentals from "./DroneRentals";

function App() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route
            path="drone-private-lessons"
            element={<DronePrivateLessons />}
          />
          <Route path="3d-mapping-survey" element={<MappingSurvey />} />
          <Route path="drone-inspection" element={<DroneInspection />} />
          <Route path="drone-rentals" element={<DroneRentals />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
