import React from "react";
import {
  FaTelegram,
  FaTwitter,
  FaInstagram,
  FaPhoneAlt,
  FaMapPin,
  FaEnvelope,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "../i18n";

export const Faq = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <section id="faq" className="py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-md-6">
              <h2 className="mb-5">{t("FAQs")}</h2>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {t("FAQQues1")}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{t("FAQAns1")}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {t("FaqQues2")}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{t("FaqAns2")}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {t("FaqQues3")}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{t("FaqAns3")}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {t("FaqQues4")}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{t("FaqAns4")}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {t("FaqQues5")}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{t("FaqAns5")}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d17703600.795224633!2d25.473618!3d57.203985!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eecfb0e5073ded%3A0x400cfcd68f2fe30!2sRiga%2C%20Latvia!5e0!3m2!1sen!2sus!4v1733002461608!5m2!1sen!2sus"
                // width="350"
                // height="400"
                allowFullScreen=""
                loading="lazy"
                title="map"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section
        id="contact"
        className="py-4 bg-light text-dark"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="row g-4">
            <div className="col-6 col-md-3 d-flex justify-content-center align-items-center">
              <FaMapPin className="me-2" />
              Riga,Latvia
            </div>
            <div className="col-6 col-md-3 d-flex justify-content-center align-items-center">
              <FaPhoneAlt className="me-2" />
              +37127102911
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
              <FaEnvelope className="me-2" />
              pavels.polezajevs@gmail.com
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-center">
              <a
                href="/"
                className="me-2 text-dark fs-5"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                href="/"
                className="me-2 text-dark fs-5"
                target="_blank"
                rel="noreferrer"
              >
                <FaTelegram />
              </a>
              <a
                href="/"
                className="me-2 text-dark fs-5"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
