import React from "react";
import about_img from "../assets/images/about_img.jpg";
import { useTranslation } from "react-i18next";
import "../i18n";

const About = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <section id="about" className="py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-md-6">
              <img
                src={about_img}
                data-aos="fade-up"
                className="rounded-5"
                alt="about_img"
              />
            </div>
            <div className="col-12 col-md-6" data-aos="fade-up">
              <h2>{t("AboutUs")}</h2>
              <p className="subtitle">
                {t("AboutUsTitle")}
              </p>
              <p className="lead description">
                {t("AboutUsDescription")}
              </p>
              <a
                href="mailto:pavels.polezajevs@gmail.com"
                className="btn btn-lg"
                target="_blank"
                rel="noreferrer"
              >
                {t("ContactUs")}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
