import React from "react";
import Header from "./components/Header";
import Features from "./components/Features";
import About from "./components/About";
import { Faq } from "./components/Faq";
import Projects from "./components/Projects";

const Home = () => {
  return (
    <>
      <Header />
      <Features />
      <About />
      <Projects />
      <Faq />
    </>
  );
};

export default Home;
