import React from "react";
import img1 from "./assets/images/inspection1.jpeg";
import img2 from "./assets/images/inspection2.jpg";
import img3 from "./assets/images/inspection3.jpg";
import img4 from "./assets/images/inspection4.jpg";
import { useTranslation } from "react-i18next";
import "./i18n";

const DroneInspection = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <section id="header2">
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-md-12" data-aos="fade-up">
              <h3>{t("Drone Inspection")}</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="content">
        <div className="container">
          <div className="row g-4" data-aos="fade-up">
            <div className="col-12 col-md-12 text-center">
              <p className="lead description">
                {t("DroneInspectionDesc1")}
              </p>
              <p className="lead description">
                {t("DroneInspectionDesc2")}
              </p>
              <a
                href="mailto:pavels.polezajevs@gmail.com"
                className="btn btn-lg"
                target="_blank"
                rel="noreferrer"
              >
                {t("ContactUs")}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="content">
        <div className="container">
          <div className="row g-4" data-aos="fade-up">
            <div className="col-12 col-md-3">
              <img
                src={img1}
                data-aos="fade-up"
                className="rounded-5 w-100 h-100"
                alt="img1"
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                src={img2}
                data-aos="fade-up"
                className="rounded-5 w-100 h-100"
                alt="img2"
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                src={img3}
                data-aos="fade-up"
                className="rounded-5 w-100 h-100"
                alt="img3"
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                src={img4}
                data-aos="fade-up"
                className="rounded-5 w-100 h-100"
                alt="img4"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DroneInspection;
