import React from "react";
import drone1 from "./assets/images/Dji m30t.jpeg";
import drone2 from "./assets/images/avata 2.jpeg";
import { useTranslation } from "react-i18next";
import "./i18n";

const DronePrivateLessons = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <section id="header2">
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-md-12" data-aos="fade-up">
              <h3>{t("Drone Private Lessons")}</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="content">
        <div className="container">
          <div
            className="row d-flex flex-column-reverse flex-md-row g-4"
            data-aos="fade-up"
          >
            <div className="col-12 col-md-6">
              <h2>{t("Drone1")}</h2>
              <p className="subtitle">{t("Drone1Price")}</p>
              <p className="lead description">{t("Drone1Desc")}</p>
              <a
                href="mailto:pavels.polezajevs@gmail.com"
                className="btn btn-lg"
                target="_blank"
                rel="noreferrer"
              >
                {t("BookNow")}
              </a>
            </div>
            <div className="col-12 col-md-6">
              <img
                src={drone1}
                data-aos="fade-up"
                className="rounded-5 w-100"
                alt="DJI Matrice 30t"
              />
            </div>
          </div>
          <div className="row g-4 mt-5" data-aos="fade-up">
            <div className="col-12 col-md-6">
              <img
                src={drone2}
                data-aos="fade-up"
                className="rounded-5 w-100"
                alt="FPV DJI AVATA 2"
              />
            </div>
            <div className="col-12 col-md-6">
              <h2>{t("Drone2")}</h2>
              <p className="subtitle">{t("Drone2Price")}</p>
              <p className="lead description">
                {t("Drone2Desc")}
              </p>
              <a
                href="/"
                className="btn btn-lg"
                target="_blank"
                rel="noreferrer"
              >
                {t("BookNow")}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DronePrivateLessons;
