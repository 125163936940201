import React from "react";
import logo from "../assets/images/logo.jpeg";
import { NavLink } from "react-router";
import { useTranslation } from "react-i18next";
import "../i18n";

export const Footer = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <footer className="d-flex container flex-wrap justify-content-between align-items-center py-3 my-4">
        <p className="col-md-4 mb-0">© 2024 1drone.lv</p>

        <a
          href="/"
          className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
        >
          <img src={logo} width="100px" alt="logo" />
        </a>

        <ul className="nav col-md-4 justify-content-end">
          <li className="nav-item">
            <NavLink to="/" className="nav-link px-2">
              {t("home")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/drone-inspection" className="nav-link px-2">
              {t("Drone Inspection")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/3d-mapping-survey" className="nav-link px-2">
              {t("Mapping/3D Mapping Survey")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/drone-rentals" className="nav-link px-2">
              {t("Drone Rentals")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/drone-private-lessons" className="nav-link px-2">
              {t("Drone Private Lessons")}
            </NavLink>
          </li>
        </ul>
      </footer>

      {/* <div
        class="alert alert-info alert-dismissible fade show fixed-bottom mb-0 text-center"
        role="alert"
      >
        This website uses cookies to ensure you get the best experience on our
        website.
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div> */}
    </>
  );
};
