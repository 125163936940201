import React from "react";
import { useTranslation } from "react-i18next";
import "../i18n";

const Header = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <section id="header">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6" data-aos="fade-up">
              <h1>{t("welcome")}</h1>
              <p className="lead description">
                {t("welcomeDescription")}
              </p>
              <div className="d-flex flex-column flex-md-row">
                <a href="mailto:pavels.polezajevs@gmail.com" className="btn btn-lg me-0 me-md-2 mb-3 mb-md-0">
                  {t("BookNow")}
                </a>
                {/* <a className="btn btn-lg" href="/">
                  Our Services
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
