import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.jpeg";
import { FaGlobe } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "../i18n";
import { NavLink } from "react-router";

export const Navbar = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change language
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;

      // Toggle `is-hidden` based on scroll direction
      if (currentScroll > lastScrollTop && currentScroll > 50) {
        setIsHidden(true); // User is scrolling down
      } else {
        setIsHidden(false); // User is scrolling up
      }

      // Add `navbar-scrolled` if scrolled down
      setIsScrolled(currentScroll > 50);

      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // Prevent negative scroll values
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg fixed-top ${
          isScrolled ? "navbar-scrolled" : ""
        } ${isHidden ? "is-hidden" : "is-visible"}`}
      >
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} width="100px" alt="logo" />
          </a>
          <button
            className="navbar-toggler bg-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  {t("home")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/drone-inspection" className="nav-link">
                  {t("Drone Inspection")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/3d-mapping-survey" className="nav-link">
                  {t("Mapping/3D Mapping Survey")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/drone-rentals" className="nav-link">
                  {t("Drone Rentals")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/drone-private-lessons" className="nav-link">
                  {t("Drone Private Lessons")}
                </NavLink>
              </li>
            </ul>
            <ul className="list-unstyled d-flex ms-2 mt-2">
              <div className="dropdown">
                <a
                  className="dropdown-toggle text-white text-decoration-none"
                  href="/"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FaGlobe className="text-white" /> {t("Select Language")}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => changeLanguage("en")}
                      href="/"
                    >
                      English
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => changeLanguage("lv")}
                      href="/"
                    >
                      Latviešu
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => changeLanguage("ru")}
                      href="/"
                    >
                      Русский
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
