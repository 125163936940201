import React from "react";
import drone1 from "./assets/images/Dji m30t.jpeg";
import drone2 from "./assets/images/avata 2.jpeg";
import { useTranslation } from "react-i18next";
import "./i18n";

const DroneRentals = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language
  // };
  return (
    <>
      <section id="header2">
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-md-12" data-aos="fade-up">
              <h3>{t("Drone Rentals")}</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="content">
        <div className="container">
          <div className="row g-4" data-aos="fade-up">
            <div className="col-12 col-md-12 text-center">
              <p className="lead description">
                {t("DroneRentals1")}
              </p>
              <p className="lead description">
                {t("DroneRentals2")}
              </p>
              <a
                href="mailto:pavels.polezajevs@gmail.com"
                className="btn btn-lg"
                target="_blank"
                rel="noreferrer"
              >
                {t("ContactUs")}
              </a>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center mx-auto">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link rounded-5 active"
                    id="drone1-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#drone1-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="drone1-tab-pane"
                    aria-selected="true"
                  >
                    {t("Drone1")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link rounded-5"
                    id="drone2-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#drone2-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="drone2-tab-pane"
                    aria-selected="false"
                  >
                    {t("Drone2")}
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="drone1-tab-pane"
                  role="tabpanel"
                  aria-labelledby="drone1-tab"
                  tabIndex="0"
                >
                  <img
                    src={drone1}
                    data-aos="fade-up"
                    className="rounded-5 w-100"
                    alt="DJI Matrice 30t"
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="drone2-tab-pane"
                  role="tabpanel"
                  aria-labelledby="drone2-tab"
                  tabIndex="0"
                >
                  <img
                    src={drone2}
                    data-aos="fade-up"
                    className="rounded-5 w-100"
                    alt="FPV DJI AVATA 2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DroneRentals;
